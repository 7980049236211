import React, { useState, useEffect } from "react";
import '../styles/components/_musicevent.scss';

// NOTE: see /util/dateTime.js for determining the timestamp of a show time in any time zone.

const isDateTimeFormatOptions = () => {
	const msAnyRandomTime = 1118246400000;
	const testDate = new Date(msAnyRandomTime);
	try{
		const aussie = new Intl.DateTimeFormat([], {timeZone: 'Australia/Perth'}).format(testDate);
		const brazil = new Intl.DateTimeFormat([], {timeZone: 'America/Sao_Paulo'}).format(testDate);
		return aussie !== brazil;
	}catch(e){
		return false;
	}
};

const getDate = (timestamp, timeZone) => {
	if(isDateTimeFormatOptions()){
		return new Intl.DateTimeFormat([], {timeZone}).format(new Date(timestamp));
	}else{
		return new Intl.DateTimeFormat([]).format(new Date(timestamp));
	}
};

const getLocation = ({displayName, city, state, country})=> `${displayName}, ${city}, ${state ? state + ', ' : ' '}${country}`;

const MusicEvent = ({event}) => (
	<li key={event.eventID} songkickid={event.songkickID} className="MusicEvent">
		<div className="MusicEvent__date">
			{getDate(event.startsAt, event.timezone)}
		</div>
		<div className="MusicEvent__details">
			<span className="MusicEvent__details__venueName">
				{event.songkickVenue?.displayName ? getLocation(event.songkickVenue) : getLocation(event.oldVenue)}
			</span>
			<span className="MusicEvent__details__displayName italic">
				{event.displayName}
			</span>
			<span className="MusicEvent__details__eventTypeAndDescription">
				<span className="MusicEvent__details__eventType">
					[{event.eventType}]
				</span>
				<span className={"MusicEvent__details__linkedDescription " + (event.songkickURI || event.uri ? "" : "noDisplay")}>
					<a href={event.songkickURI || event.uri}>{event.description}</a>
				</span>
				<span className={"MusicEvent__details__unlinkedDescription " + (!event.songkickURI && !event.uri ? "" : "noDisplay")}>
					{event.description}
				</span>
			</span>
			<span className={"MusicEvent__details__otherActs " + (event.otherActs ? "" : "noDisplay")}>
				Other acts:
				{event.otherActs && event.otherActs.reduce(
					(prev, curr) => prev + ', ' + curr,
					''
				).slice(1)}
			</span>
			<span className={"MusicEvent__details__musicianNames " + (event.musicianNames ? "" : "noDisplay")}>
				Musicians with me:
				{event.musicianNames && event.musicianNames.reduce(
					(prev, curr) => prev + ', ' + curr,
					''
				).slice(1)}
			</span>
		</div>
	</li>
);

const MusicEventList = ({events}) => {
	const [years, setYears] = useState([]);
	const [countries, setCountries] = useState([]);
	const [eventTypes, setEventTypes] = useState([]);
	const [hasReversedYears, setHasReversedYears] = useState(false);

	const [isShowFilters, setIsShowFilters] = useState(false);
	const [yearsChecked, setYearsChecked] = useState([]);
	const [countriesChecked, setCountriesChecked] = useState([]);
	const [eventTypesChecked, setEventTypesChecked] = useState([]);

	function getYearFromTimestamp (timestamp, timeZone) {
		if(isDateTimeFormatOptions()){
			return new Intl.DateTimeFormat([], {timeZone, year: 'numeric'}).format(new Date(timestamp));
		}else{
			return new Intl.DateTimeFormat([], {year: 'numeric'}).format(new Date(timestamp));
		}
	}

	function getCountryFromVenue ({country}) {
		return country;
	}

	function addYear (year) {
		if(!years.includes(year)) setYears([...years, year]);
	}

	function addCountry (country) {
		if(!countries.includes(country)) setCountries([...countries, country]);
	}

	function addEventType (eventType) {
		if(!eventTypes.includes(eventType)) setEventTypes([...eventTypes, eventType]);
	}

	function handleClickShowHideFilters () {
		if(!hasReversedYears) {
			setYears(years.toReversed());
			setHasReversedYears(true);
		}
		setIsShowFilters(!isShowFilters);
	}

	function handleCheckYear (year) {
		const iPos = yearsChecked.indexOf(year);
		if(iPos < 0){
			setYearsChecked([...yearsChecked, year]);
		} else {
			const newYearsChecked = [...yearsChecked];
			newYearsChecked.splice(iPos, 1);
			setYearsChecked(newYearsChecked);
		}
	}

	function handleCheckCountry (country) {
		const iPos = countriesChecked.indexOf(country);
		if(iPos < 0){
			setCountriesChecked([...countriesChecked, country]);
		} else {
			const newCountriesChecked = [...countriesChecked];
			newCountriesChecked.splice(iPos, 1);
			setCountriesChecked(newCountriesChecked);
		}
	}

	function handleCheckEventType (eventType) {
		const iPos = eventTypesChecked.indexOf(eventType);
		if(iPos < 0){
			setEventTypesChecked([...eventTypesChecked, eventType]);
		} else {
			const newEventTypesChecked = [...eventTypesChecked];
			newEventTypesChecked.splice(iPos, 1);
			setEventTypesChecked(newEventTypesChecked);
		}
	}

	const eventsFiltered =
		events.filter(
			(event, i) => {
				const year = getYearFromTimestamp(event.startsAt, event.timezone);
				const country = getCountryFromVenue(event.songkickVenue || event.oldVenue);
				const eventType = event.eventType;

				if(!hasReversedYears) {
					addYear(year);
					addCountry(country);
					addEventType(eventType);
				}

				if (  // easier to specify when NOT to show an event
					(yearsChecked.length && !yearsChecked.includes(year)) ||
					(countriesChecked.length && !countriesChecked.includes(country)) ||
					(eventTypesChecked.length && !eventTypesChecked.includes(eventType))
				) {
					return false;
				} else {
					return true;
				}
			}
		)
	;

	useEffect(() => {
		const handleEsc = (event) => {
			if (event.key === 'Escape') {
				setIsShowFilters(false);
			}
		};
		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);

	return (
		<div className="MusicEventList">
			<p className="card">
				<button className="card MusicEventList__toggleFilters" onClick={handleClickShowHideFilters} onKeyDown={handleClickShowHideFilters}>
					Click to show/hide filters
				</button>
			</p>
			<p className="MusicEventList__filteredCounter">
				Showing {eventsFiltered.length} of {events.length} total events
			</p>
			{isShowFilters && (
				<div className="MusicEventList__filters">

					<form id="filterYear">
						<fieldset>
							<legend>
								Year
							</legend>
							<label>
								<input type="button" name="resetYear"
								onClick={() => setYearsChecked([])} onKeyDown={() => setYearsChecked([])}
								className="MusicEventList__filters__reset"
								/>
								Reset
							</label>
							{years.map(
								(year, i) => (
									<label key={i}>
										<input type="checkbox" name={year} checked={yearsChecked.includes(year)}
										onChange={() => handleCheckYear(year)}
										/>
										{year}
									</label>
								)
							)}
						</fieldset>
					</form>

					<form id="filterCountry">
						<fieldset>
							<legend>
								Country
							</legend>
							<label>
								<input type="button" name="resetCountry"
								onClick={() => setCountriesChecked([])} onKeyDown={() => setCountriesChecked([])}
								className="MusicEventList__filters__reset"
								/>
								Reset
							</label>
							{countries.map(
								(country, i) => (
									<label key={i}>
										<input type="checkbox" name={country} checked={countriesChecked.includes(country)}
										onChange={() => handleCheckCountry(country)}
										/>
										{country}
									</label>
								)
							)}
						</fieldset>
					</form>

					<form id="filterEventType">
						<fieldset>
							<legend>
								Event type
							</legend>
							<label>
								<input type="button" name="resetEventType"
								onClick={() => setEventTypesChecked([])} onKeyDown={() => setEventTypesChecked([])}
								className="MusicEventList__filters__reset"
								/>
								Reset
							</label>
							{eventTypes.map(
								(eventType, i) => (
									<label key={i}>
										<input type="checkbox" name={eventType} checked={eventTypesChecked.includes(eventType)}
										onChange={() => handleCheckEventType(eventType)}
										/>
										{eventType}
									</label>
								)
							)}
						</fieldset>
					</form>

				</div>
			)}
			<ul className="MusicEventList__events">
				{eventsFiltered.map(
					(event, i) => (<MusicEvent event={event} key={i}/>)
				)}
			</ul>
		</div>
	)
};

export { isDateTimeFormatOptions, MusicEventList };
export default MusicEvent;
