import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { isDateTimeFormatOptions, MusicEventList } from "../components/MusicEvent";

import myEvents from "../eventsMusic.json";

// NOTE: see /util/dateTime.js for determining the timestamp of a show time in any time zone.

/*
 * Start with a throbber in the list of events; once we have fetched some events we can rid of the throbber.
 * In the top paragraph, mention that one can click through to the events subdomain to see a static list there.
 * We also want to fetch from Songkick and stack on any new ones that are not already in our existing collection.
 * Fetch:
 *    https://www.gatsbyjs.com/docs/conceptual/data-fetching/#fetching-data-at-client-side-runtime
 */

const EventsPage = () => {
	const [events] = useState([...myEvents]);
	const [hasReversed, setHasReversed] = useState(false);
	if(!hasReversed){
		events.reverse();
		setHasReversed( true );
	}
	return (
		<Layout>
			<Helmet>
				{/*  <script defer src="//widget.songkick.com/5836544/widget.js" />  */}
				<script defer src="//widgetv3.bandsintown.com/main.min.js" />
			</Helmet>
			<Seo title="Events" />
			<h2>Events</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/IMGP1846.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>
			<h3>Shows: now and then</h3>
			<p>
			     The <a href="#history">History</a> section below (after Bandsintown) is a nearly complete list of all musical events I've been a part of.
				 (Use the filter section to narrow down the results.)
				 If I'm missing one that you attended or were a part of, please <Link to="/contact/">let me know</Link>!
		    </p>

			<h4>
				Songkick
			</h4>
			<p className="externalEventWidget">
				<a href="//www.songkick.com/artists/5836544" className="songkick-widget" data-theme="dark" data-track-button="on" data-detect-style="true" data-locale="en">Songkick: show dates</a>
			</p>

			<h4>
				Bandsintown
			</h4>
			<p className="externalEventWidget">
				<a className="bit-widget-initializer"
					data-artist-name="id_3719520"

					data-background-color="#ffffff"
					data-separator-color="#DDDDDD"
					data-text-color="#424242"
					data-font="Helvetica"
					data-auto-style="true"

					data-button-label-capitalization="capitalize"
					data-header-capitalization="undefined"
					data-location-capitalization="capitalize"
					data-venue-capitalization="capitalize"
					data-display-local-dates="true"
					data-local-dates-position="tab"
					data-display-past-dates="true"
					data-display-details=""
					data-display-lineup=""
					data-display-start-time=""
					data-social-share-icon="true"
					data-display-limit="all"

					data-date-format="MMM. D, YYYY"
					data-date-orientation="horizontal"
					data-date-border-color="#4A4A4A"
					data-date-border-width="1px"
					data-date-capitalization="undefined"
					data-date-border-radius="10px"

					data-event-ticket-cta-size="medium"
					data-event-custom-ticket-text="undefined"
					data-event-ticket-text="TICKETS"
					data-event-ticket-icon=""
					data-event-ticket-cta-text-color="#FFFFFF"
					data-event-ticket-cta-bg-color="#4A4A4A"
					data-event-ticket-cta-border-color="#4A4A4A"
					data-event-ticket-cta-border-width="0px"
					data-event-ticket-cta-border-radius="4px"

					data-sold-out-button-text-color="#FFFFFF"
					data-sold-out-button-background-color="#4A4A4A"
					data-sold-out-button-border-color="#4A4A4A"
					data-sold-out-button-clickable="true"

					data-event-rsvp-position="left"
					data-event-rsvp-cta-size="medium"
					data-event-rsvp-only-show-icon="undefined"
					data-event-rsvp-text="REMIND ME"
					data-event-rsvp-icon=""
					data-event-rsvp-cta-text-color="#4A4A4A"
					data-event-rsvp-cta-bg-color="#FFFFFF"
					data-event-rsvp-cta-border-color="#4A4A4A"
					data-event-rsvp-cta-border-width="1px"
					data-event-rsvp-cta-border-radius="4px"

					data-follow-section-position="top"
					data-follow-section-alignment="center"
					data-follow-section-header-text="Get updates on new shows, new music, and more."
					data-follow-section-cta-size="medium"
					data-follow-section-cta-text="FOLLOW"
					data-follow-section-cta-icon="true"
					data-follow-section-cta-text-color="#FFFFFF"
					data-follow-section-cta-bg-color="#4A4A4A"
					data-follow-section-cta-border-color="#4A4A4A"
					data-follow-section-cta-border-width="0px"
					data-follow-section-cta-border-radius="4px"

					data-play-my-city-position="bottom"
					data-play-my-city-alignment="Center"
					data-play-my-city-header-text="Don’t see a show near you?"
					data-play-my-city-cta-size="medium"
					data-play-my-city-cta-text="REQUEST A SHOW"
					data-play-my-city-cta-icon="true"
					data-play-my-city-cta-text-color="#FFFFFF"
					data-play-my-city-cta-bg-color="#4A4A4A"
					data-play-my-city-cta-border-color="#4A4A4A"
					data-play-my-city-cta-border-width="0px"
					data-play-my-city-cta-border-radius="4px"

					data-optin-font=""
					data-optin-text-color=""
					data-optin-bg-color=""
					data-optin-cta-text-color=""
					data-optin-cta-bg-color=""
					data-optin-cta-border-width=""
					data-optin-cta-border-radius=""
					data-optin-cta-border-color=""

					data-language="en"
					data-layout-breakpoint="900"
					data-app-id=""
					data-affil-code=""
					data-bit-logo-position="bottomRight"
					data-bit-logo-color="#CCCCCC"

					href="//bnds.us/v2gteg"
				>Bandsintown: show dates</a>
			</p>

			<h4 className={isDateTimeFormatOptions() ? "noDisplay" : null}>
				Warning: dates below may be one day off due to time zones.
			</h4>

			<h4 id="history">
				History
			</h4>

			<MusicEventList events={events} />

		</Layout>
	);
};

export default EventsPage;
